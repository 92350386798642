import SpecialNodeKind from 'enums/SpecialNodeKind';
import FlowNode from 'types/FlowNode';
import RobotCategory from 'enums/RobotCategory';
import Environment from 'types/Environment';

function getNodes(flow) {
  if (!flow.nodes) return [];

  return flow.nodes.map((node) => {
    const clonedNode = { ...node };
    if (isScriptNode(node)) {
      delete clonedNode.config;
    }
    return clonedNode;
  });
}

function getEdges(flow) {
  return flow.edges || [];
}

function getVariables(flow) {
  return flow.variables || [];
}

function getScriptCells(flow) {
  const scriptCells = {};
  if (flow.nodes) {
    flow.nodes
      .filter((node) => isScriptNode(node))
      .forEach((scriptNode) => {
        const { id, config } = scriptNode;
        scriptCells[id] = config.cells;
      });
  }
  return scriptCells;
}

function isScriptNode(node: FlowNode) {
  return node.kind === SpecialNodeKind.Script;
}

function getEnvironment(flow, robotCategory: RobotCategory) {
  let latest_hcl = 'hcl==1.0.*'
  let requirements

  if (flow.environment && flow.environment.requirements) {
    requirements = flow.environment.requirements.filter(
      library => !library.startsWith('hcl')
      );
    requirements.push(latest_hcl)
  }
  else {
    requirements = [latest_hcl];
    if (robotCategory === RobotCategory.Highbond) {
      requirements.push('boxsdk==3.0.1', 'openpyxl==3.0.9', 'Jinja2==3.0.3', 'simple-salesforce==1.11.5', 'jira==3.1.1');
    }
  }
  return {
    runtime: 'python3.8',
    requirements,
  } as Environment
}

export default {
  getNodes,
  getEdges,
  getEnvironment,
  getScriptCells,
  getVariables,
};
